<template>
  <div
    class="relative flex min-h-screen flex-col justify-between pt-16 lg:pt-32"
    :class="routeColor"
  >
    <div class="fixed top-0 z-50 w-full">
      <AppHeader />
    </div>

    <div>
      <slot />
    </div>

    <footer>
      <slot name="footer">
        <AppFooter />
      </slot>
    </footer>
  </div>
</template>

<script setup>
const { Routes } = useRouteUtils();
const router = useRouter();

const routeColor = computed(() => {
  switch (router.currentRoute.value.path) {
    case Routes.ABOUT:
      return "ocean-none";
    case Routes.CAMPAIGNS:
      return "nature";
    case Routes.FOR_BUSINESS:
      return "green-white-ocean";
    case Routes.HOME:
      return "bg-default";
    case Routes.HOW_IT_WORKS:
      return "ocean-none";
    case Routes.HUMANITYS_CHECKLIST:
      return "ocean-pink-ocean";
    case Routes.PROFILE:
      return "ocean-pink";
    case Routes.THEORY_OF_GIVING:
      return "pink";
    case Routes.WHO_WE_ARE:
      return "blue";
    case Routes.YELLOW_ROOMS:
      return "yellow-low";
    case Routes.OCEAN_VOYAGES:
      return "ocean-low";
    case Routes.PLASTIC_FISCHER:
      return "ocean-low";
    case Routes.WILDLIFE:
      return "orange-low";
    default:
      return "bg-default";
  }
});
</script>

<style>
.bg-default {
  @apply bg-gradient-to-b from-main-bg from-90% to-ocean;
}

.ocean-pink {
  @apply bg-gradient-to-b from-ocean to-main-bg;
}

.ocean-pink-ocean {
  @apply bg-ocean-pink-ocean;
}

.ocean-none {
  @apply bg-gradient-to-b from-ocean to-white;
}

.pink {
  @apply bg-orange-low;
}

.blue {
  @apply bg-ocean;
}

.green-white-ocean {
  @apply bg-green-white-ocean;
}

.nature {
  @apply bg-nature-low;
}

.yellow-low {
  @apply bg-yellow-low;
}

.ocean-low {
  @apply bg-ocean-low;
}

.orange-low {
  @apply bg-orange-low;
}
</style>
